import { useEffect } from 'react'
import { useAuth } from 'contexts/authentication-context'
import { navigate } from 'gatsby'

export default () => {
  const { auth } = useAuth()

  useEffect(() => {
    if (location.hash) {
      auth
        .token!.parseFromUrl()
        .then((info: any) => {
          const tokenInfo = info[1]
          auth.tokenManager!.add('idToken', tokenInfo)
          // Window reload to navigate back to login page as authenticated
          window.location.reload()
        })
        .catch((err: any) => {
          navigate('/')
        })
    } else {
      navigate('/')
    }
  })

  return true
}
